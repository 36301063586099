import config from 'buefy/src/utils/config'
import VueFeatherIconPack from './components/VueFeatherIconPack'

import { Button, Field, Icon, Loading, Menu, Navbar, Skeleton, Slider, Snackbar, Switch } from 'buefy/src/components'

const MyBuefy = {
    install(Vue) {
        // use VueFeatherIconPack
        Vue.component('vue-feather-icon-pack', VueFeatherIconPack)
        // Options
        config.defaultIconComponent = 'vue-feather-icon-pack'
        config.defaultIconPack = 'ft'
        config.customIconPacks = {
            ft: {
                iconPrefix: ''
            }
        }
        // Components
        Vue.use(Button)
        Vue.use(Field)
        Vue.use(Icon)
        Vue.use(Loading)
        Vue.use(Menu)
        Vue.use(Navbar)
        Vue.use(Skeleton)
        Vue.use(Slider)
        Vue.use(Snackbar)
        Vue.use(Switch)
    }
}

export default MyBuefy
