<template>
    <div class="box noselect">
        <b-field label="Muscle Selection">
            <b-switch :value="value" @input="handleInput"></b-switch>
        </b-field>
    </div>
</template>

<script>
export default {
    name: 'MuscleSelection',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style></style>
