<template>
    <div class="component">
        <p class="video-section-label">Videos</p>
        <div class="columns">
            <div class="column video">
                <p class="video-title">Muscle Function Animation</p>
                <video-player
                    :vimeo-id="videos.muscle_function_animation.vimeo_id"
                    :key="videos.muscle_function_animation.vimeo_id"
                ></video-player>
            </div>
            <div class="column video">
                <p class="video-title">Cadaver Demonstration</p>
                <video-player
                    :vimeo-id="videos.cadaver_demonstration.vimeo_id"
                    :key="videos.muscle_function_animation.vimeo_id"
                ></video-player>
            </div>
        </div>
    </div>
</template>

<script>
import VideoPlayer from './VideoPlayer'

export default {
    name: 'VideoGrid',
    props: {
        videos: {
            type: Object,
            required: true
        }
    },
    components: {
        VideoPlayer
    }
}
</script>

<style lang="scss">
.video-section-label {
    color: $light-purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
}
.video-title {
    color: $purple;
    font-weight: 500;
    margin-bottom: 0.6rem;
}
.column.video {
    height: 100%;
}
.muscle-function-animation {
    .plyr--video,
    .plyr__video-wrapper,
    .plyr__poster {
        background-color: whitesmoke !important;
    }
}
</style>
