<template>
    <div class="columns is-flex is-flex-direction-column is-align-items-center">
        <div class="column">
            <object :data="notFound" type="image/svg+xml">
                <img src="@/assets/images/404-computer.png" width="380" height="223" />
            </object>
        </div>
        <div class="column has-text-centered">
            <div class="is-size-1 has-text-primary has-text-weight-medium">404</div>
            <div class="is-size-6 mb-5">This is not the web page you are looking for.</div>
            <div><b-button type="is-primary" tag="a" href="/">Go Home</b-button></div>
        </div>
    </div>
</template>

<script>
import links from '../constants/links'

export default {
    name: 'NotFound',
    mounted() {
        document.title = '404 - Page not found'
    },
    data() {
        return {
            notFound: require('@/assets/images/404-computer.svg'),
            links
        }
    }
}
</script>
