import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ComingSoon from '@/views/ComingSoon'
import MuscleDetail from '../views/MuscleDetail'
import NotFound from '../views/NotFound'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/muscles/:id',
        name: 'MuscleDetail',
        component: MuscleDetail,
        props: true,
        beforeEnter: (to, from, next) => {
            function isValid(param) {
                return store.getters.getMuscleById(param) != null
            }

            if (!isValid(to.params.id)) {
                next({ name: 'NotFound' })
            }

            next()
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '*',
        redirect: '/404'
    }
]

// replace home with coming soon if site not ready
if (process.env.VUE_APP_BUILD_TARGET === 'coming-soon') {
    routes[0] = {
        path: '/',
        name: 'ComingSoon',
        component: ComingSoon
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
