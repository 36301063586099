<template>
    <div class="columns coming-soon">
        <div class="column">
            <div class="is-size-1 has-text-primary has-text-weight-bold has-text-centered mb-5">Coming soon!</div>
            <div class="is-size-5 sub has-text-weight-medium has-text-centered">
                A digital resource library with interactive anatomy resources and<br />
                multimedia content for Adult Spasticity and Cervical Dystonia
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    mounted() {
        document.title = 'Coming Soon'
    }
}
</script>

<!-- Resposinve CSS -->
<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+mobile
    .workbook-image-container
        display: flex
        align-items: center
        justify-content: center
</style>

<style>
.coming-soon .sub {
    color: #4a4a4a;
}
.coming-soon .is-size-1 {
    font-size: 5.7rem !important;
    line-height: normal;
}
</style>
