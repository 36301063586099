<template>
    <b-menu class="accordion-menu">
        <b-menu-list>
            <b-menu-item
                label="Prescribing Information"
                :href="links.isi.PRESCRIBING_INFORMATION"
                target="_blank"
                class="is-hidden-tablet alt-menu-item"
            ></b-menu-item>
            <b-menu-item
                label="Medication Guide"
                :href="links.isi.MEDICATION_GUIDE"
                target="_blank"
                class="is-hidden-tablet alt-menu-item"
            ></b-menu-item>
            <b-menu-item label="Home" tag="router-link" to="/" :active="isHomeActive" @click.native="onChange"></b-menu-item>
            <b-menu-item
                v-for="group in allGroups"
                v-bind:key="group.id"
                :active="group.id == activeGroupId"
                :expanded="group.id == activeGroupId"
            >
                <template #label="props">
                    {{ group.name }}
                    <b-icon class="is-pulled-right" :icon="props.expanded ? 'chevron-down' : 'chevron-up'"></b-icon>
                </template>
                <b-menu-item
                    v-for="item in group.items"
                    v-bind:key="item.id"
                    :label="item.name"
                    :active="item.id == $route.params.id"
                    tag="router-link"
                    :to="{ name: 'MuscleDetail', params: { id: item.id } }"
                    @click.native="onChange"
                ></b-menu-item>
            </b-menu-item>
        </b-menu-list>
    </b-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import links from '../../constants/links'

export default {
    props: {
        autoExpandGroupsOnRouteChange: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return { links }
    },
    methods: {
        onChange: function () {
            this.$emit('change')
        }
    },
    computed: {
        ...mapGetters(['allGroups', 'getGroupForMuscleId']),
        isHomeActive: function () {
            return this.$route.name === 'Home'
        },
        activeGroupId: function () {
            if (this.autoExpandGroupsOnRouteChange) {
                let activeMuscleId =
                    this.$route && this.$route.name === 'MuscleDetail' && this.$route.params && this.$route.params.id
                let group = activeMuscleId && this.getGroupForMuscleId(activeMuscleId)
                return group && group.id
            } else {
                return undefined
            }
        }
    }
}
</script>

<style lang="scss">
.menu {
    font-size: 0.95rem !important;
}
.accordion-menu {
    .menu-list {
        li ul {
            border-left: 0;
            padding-left: 0;
            margin: 0 0 0.75em 0;
        }
        a {
            padding: 1em 0.75em;
            border-bottom: 2px solid #eeeeee;
            color: $purple;
            font-weight: bold;
        }
        a:hover {
            background-color: whitesmoke;
            color: $purple;
        }
        a.is-active {
            background-color: transparent;
            color: $light-purple;
        }
        a.is-expanded {
            border-bottom: 2px solid transparent;
        }
        .alt-menu-item {
            a {
                font-weight: normal;
                background-color: whitesmoke;
            }
            a.is-active {
                background-color: whitesmoke;
                color: $purple;
            }
        }
        a.is-active:hover {
            background-color: whitesmoke;
            color: $light-purple;
        }
        // Nested level
        > li > ul > li > a {
            border-bottom: none;
            padding: 0.35em 0.75em;
            font-weight: normal;
            font-size: 0.95rem;
        }
        > li > ul > li > a.is-active {
            font-weight: 500;
            color: $purple;
            background-color: $light-purple-background;
        }
    }
}
</style>
