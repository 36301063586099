<template>
    <div class="container navigation-container">
        <b-navbar class="py-2 main" :active.sync="isMenuActive">
            <template #brand>
                <b-navbar-item tag="router-link" :to="{ path: '/' }" class="navbar-logo-item">
                    <object :data="botoxLogo" type="image/svg+xml" width="171" height="54">
                        <img src="@/assets/images/botox-logo.png" width="171" height="54" />
                    </object>
                </b-navbar-item>
                <div class="mobile-only-title title is-size-6 is-hidden-tablet">Digital Resource Library</div>
            </template>
            <template #start>
                <hide-at :breakpoints="breakpoints" breakpoint="small">
                    <b-navbar-item tag="div" class="title is-size-5 is-hidden-desktop"> Digital Resource Library </b-navbar-item>
                </hide-at>
                <show-at :breakpoints="breakpoints" breakpoint="small">
                    <app-menu
                        class="is-hidden-desktop"
                        :autoExpandGroupsOnRouteChange="false"
                        @change="isMenuActive = !isMenuActive"
                    ></app-menu>
                </show-at>
            </template>

            <template #end>
                <b-navbar-item tag="a" :href="links.isi.BOTOX_PI" target="_blank" class="is-size-7 is-hidden-touch">
                    Prescribing Information
                </b-navbar-item>
                <b-navbar-item tag="a" class="is-hidden-touch has-text-weight-light"> | </b-navbar-item>
                <b-navbar-item tag="a" :href="links.isi.MEDICATION_GUIDE" target="_blank" class="is-size-7 is-hidden-touch">
                    Medication Guide
                </b-navbar-item>
            </template>
        </b-navbar>
    </div>
</template>

<script>
import breakpoints from '../../constants/breakpoints'
import { showAt, hideAt } from 'vue-breakpoints'
import Menu from './Menu'
import links from '../../constants/links'

export default {
    components: {
        'app-menu': Menu,
        showAt,
        hideAt
    },
    data() {
        return {
            botoxLogo: require('@/assets/images/botox-logo.svg'),
            links,
            breakpoints,
            isMenuActive: false
        }
    }
}
</script>

<!-- Resposinve CSS -->
<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+touch
    .navbar-logo-item
        object, object > img
            width: 100px !important
            height: 31px !important
    .navbar-menu
        padding: 0 !important
    .navbar.main
        padding-bottom: 0 !important
</style>

<style lang="scss">
.navbar .title {
    color: $purple;
    margin-left: 5rem;
}
.navbar .mobile-only-title {
    margin: auto 0 !important;
}
.navbar a {
    color: $purple;
    font-weight: bold;
}
.navbar-menu .accordion-menu > .menu-list > li:last-child a {
    border-bottom: 2px solid transparent;
}
.navbar-menu .accordion-menu > .menu-list > li:first-child {
    border-top: 1px solid #dadada;
}
object {
    pointer-events: none;
}
</style>
