<template>
    <div class="component">
        <b-skeleton :rounded="false" :active="!hasVideoLoaded" height="268px"></b-skeleton>
        <div class="player-container" v-show="hasVideoLoaded">
            <vimeo-player :video-id="vimeoId" :options="options" :player-height="268" @ready="onReady"></vimeo-player>
        </div>
    </div>
</template>
<script>
export default {
    name: 'VideoPlayer',
    props: {
        vimeoId: String
    },
    data() {
        return {
            hasVideoLoaded: false,
            options: {
                allowfullscreen: true,
                dnt: true
            }
        }
    },
    methods: {
        onReady() {
            this.hasVideoLoaded = true
        }
    },
    watch: {
        vimeoId: function () {
            this.hasVideoLoaded = false
        }
    }
}
</script>

<style>
.player-container iframe {
    max-width: 100%;
    width: 100% !important;
}
</style>
