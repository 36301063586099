const BOTOX_PI = 'https://www.rxabbvie.com/pdf/botox_pi.pdf'
const MEDICATION_GUIDE = 'https://www.rxabbvie.com/pdf/botox_medguide.pdf'
const HOME = '/'
const CONTACT = 'https://www.abbvie.com/contactus.html'
const PRIVACY = 'https://privacy.abbvie/privacy-policies/us-privacy-policy.html'
const TERMS = 'https://www.abbvie.com/termsofuse.html'
const ABOUT = 'https://www.abbvie.com/our-company.html'
const ABBVIE_HOME = 'https://www.abbvie.com/'
const PRIVACY_CHOICES = 'https://abbviemetadata.my.site.com/AbbvieDSRM'

export default {
    isi: {
        BOTOX_PI,
        MEDICATION_GUIDE
    },
    footer: {
        HOME,
        ABBVIE_HOME,
        CONTACT,
        PRIVACY,
        TERMS,
        ABOUT,
        PRIVACY_CHOICES
    }
}
