<template>
    <div class="columns">
        <div class="column">
            <div class="is-size-3 has-text-primary has-text-weight-medium">Digital Resource Library</div>
            <div class="is-size-6 mb-5">
                A companion website for printed BOTOX<sup>&reg;</sup>
                training materials.
            </div>
            <div class="is-size-6 has-text-primary has-text-weight-bold">
                Resources for Adult Spasticity and Cervical Dystonia
            </div>
            <div class="is-size-6">
                Use the menu to explore interactive videos, animations, and 3D models for approved muscles by indication.
            </div>
        </div>
        <div class="column workbook-image-container">
            <img src="@/assets/images/workbooks.png" height="416" width="344" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    mounted() {
        document.title = 'Digital Resource Library'
    }
}
</script>

<!-- Resposinve CSS -->
<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+mobile
    .workbook-image-container
        display: flex
        align-items: center
        justify-content: center
</style>
