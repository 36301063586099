<script src="../../../.eslintrc.js"></script>
<template>
    <footer class="footer">
        <div class="container">
            <div class="column py-0">
                <a class="abbvie-logo" :href="links.footer.ABBVIE_HOME" target="_blank" aria-current="page">
                    <object :data="abbvieLogo" type="image/svg+xml">
                        <img src="@/assets/images/abbvie-logo.png" width="279" height="48" />
                    </object>
                </a>
            </div>
            <div class="column is-size-7">
                &copy; 2023 AbbVie. All rights reserved.<br />
                BOTOX&reg; and its design are registered trademarks of Allergan, Inc., an AbbVie company.<br />
                US-BNO-230224 03/2024 023181
            </div>
            <div class="column">
                <div class="columns is-mobile">
                    <div class="column footer-links is-flex">
                        <div><a :href="links.footer.HOME">Home</a></div>
                        <div><a :href="links.footer.CONTACT" target="_blank">Contact Us</a></div>
                        <div><a :href="links.footer.TERMS" target="_blank">Terms of Use</a></div>
                        <div><a :href="links.footer.ABOUT" target="_blank">About AbbVie</a><sup>&reg;</sup></div>
                        <div><a :href="links.footer.PRIVACY" target="_blank">Privacy Notice</a></div>
                        <div><a id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</a></div>
                        <div class="privacy-options">
                            <a :href="links.footer.PRIVACY_CHOICES" target="_blank">
                                Your Privacy Choices<object :data="privacyOptionsIcon" type="image/svg+xml">
                                    <img src="@/assets/images/privacy-options-icon.png" width="30" height="14" />
                                </object>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import links from '../../constants/links'
export default {
    data() {
        return {
            abbvieLogo: require('@/assets/images/abbvie-logo.svg'),
            privacyOptionsIcon: require('@/assets/images/privacy-options-icon.svg'),
            oneTrustID: '018e79b8-75a0-787e-b004-90332546e9bd',
            links
        }
    },
    computed: {
        otID: function () {
            return this.oneTrustID.concat(process.env.NODE_ENV === 'development' ? '-test' : '')
        }
    },
    mounted() {
        let autoBlockScript = document.createElement('script')
        autoBlockScript.setAttribute('src', `https://cdn.cookielaw.org/consent/${this.otID}/OtAutoBlock.js`)
        autoBlockScript.setAttribute('type', 'text/javascript')
        document.head.appendChild(autoBlockScript)

        let oneTrustScript = document.createElement('script')
        oneTrustScript.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js')
        oneTrustScript.setAttribute('type', 'text/javascript')
        oneTrustScript.setAttribute('charset', 'UTF-8')
        oneTrustScript.dataset.domainScript = `${this.otID}`
        document.head.appendChild(oneTrustScript)

        // /* add window function - callback from OT */
        // window.OptanonWrapper = () => {
        //     OneTrust.OnConsentChanged(function (e) {
        //     })
        // }
    }
}
</script>

<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+touch
    .footer-links
        flex-direction: column

        &> div
            margin-left: 0 !important
            margin-top: 1rem
</style>

<style>
footer.footer {
    background-color: #000000;
    color: #ffffff;
}
.footer .abbvie-logo {
    position: relative;
    display: inline-block;
}
.footer-links > div {
    margin-left: 2.5rem;
}
.footer-links > div:first-child {
    margin-left: 0;
}
.footer-links a {
    color: #ffffff;
    font-size: 0.75rem;
    text-decoration: underline;
}
.footer-links a:hover {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
}
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
    color: #ffffff !important;
    border: none !important;
    padding: 0 !important;
    font-size: 0.75rem !important;
}
#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
    background-color: transparent !important;
}
.footer-links sup,
sub {
    vertical-align: baseline;
    position: relative;
    top: -0.2em;
}
.footer-links sub {
    top: 0.4em;
}
.privacy-options object {
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
}
</style>
