import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// install buefy
import MyBuefy from './MyBuefy'
Vue.use(MyBuefy)

import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)

// install logger
import VueLogger from 'vuejs-logger'
const isProduction = process.env.NODE_ENV === 'production'

const options = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}

Vue.use(VueLogger, options)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
