<template>
    <div class="component">
        <div class="columns root-container is-flex is-gapless">
            <div class="column is-half is-flex is-flex-direction-column" ref="infoContainer">
                <div class="muscle-info">
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <a>{{ groupName }}</a>
                            </li>
                        </ul>
                    </nav>
                    <h3 class="is-size-3 has-text-primary has-text-weight-medium">
                        {{ muscleName }}
                    </h3>
                </div>
                <hide-at :breakpoints="breakpoints" breakpoint="small">
                    <div class="widgets columns">
                        <div class="column is-three-quarters">
                            <muscle-selection v-model="isMuscleSelected"></muscle-selection>
                            <muscle-depth v-model="currentDepth" :depth="depthCount"></muscle-depth>
                        </div>
                    </div>
                </hide-at>
            </div>
            <div class="column is-half is-marginless is-paddingless">
                <model-renderer
                    :src="modelPath"
                    :eligibleChildNodes="eligibleChildNodes"
                    :visibleChildNodes="visibleChildNodes"
                ></model-renderer>
            </div>
            <show-at :breakpoints="breakpoints" breakpoint="small">
                <div class="column">
                    <div class="widgets columns">
                        <div class="column is-three-quarters">
                            <muscle-selection v-model="isMuscleSelected"></muscle-selection>
                            <muscle-depth v-model="currentDepth" :depth="depthCount"></muscle-depth>
                        </div>
                    </div>
                </div>
            </show-at>
        </div>
        <video-grid id="videos" v-if="videos" :videos="videos"></video-grid>
    </div>
</template>

<script>
import breakpoints from '../constants/breakpoints'
import { showAt, hideAt } from 'vue-breakpoints'
import { mapGetters } from 'vuex'
import MuscleSelection from '../components/muscle-detail/MuscleSelection'
import MuscleDepth from '../components/muscle-detail/MuscleDepth'
import ModelRenderer from '../components/muscle-detail/ModelRenderer'
import VideoGrid from '../components/muscle-detail/VideoGrid'

export default {
    name: 'MuscleDetail',
    props: {
        id: String
    },
    data() {
        return {
            group: null,
            muscle: null,
            posture: null,
            breakpoints,
            currentDepth: 0,
            lastKnownModelPath: null,
            isMuscleSelected: false
        }
    },
    components: {
        MuscleSelection,
        MuscleDepth,
        VideoGrid,
        ModelRenderer,
        showAt,
        hideAt
    },
    mounted() {
        this.refreshContents()
    },
    methods: {
        refreshContents() {
            this.group = this.getGroupForMuscleId(this.id)
            this.muscle = this.getMuscleById(this.id)
            document.title = this.muscleName

            // fetch posture
            let postureId = this.muscle && this.muscle.model.posture_id
            this.posture = postureId && this.getPostureById(postureId)

            // If the posture is same, no need to update current depth
            if (this.lastKnownModelPath !== this.modelPath) {
                this.currentDepth = this.depthCount > 0 ? 0 : -1
            }
            this.lastKnownModelPath = this.modelPath
        }
    },
    computed: {
        ...mapGetters(['getMuscleById', 'getGroupForMuscleId', 'getPostureById']),
        muscleName: function () {
            return this.muscle && this.muscle.name
        },
        groupName: function () {
            return this.group && this.group.name
        },
        modelPath: function () {
            return (this.posture && this.posture.path) || ''
        },
        eligibleChildNodes: function () {
            return this.posture && this.posture.depth && this.posture.depth.visibility && this.posture.depth.visibility['0']
        },
        visibleChildNodes: function () {
            if (this.isMuscleSelected) {
                return this.muscle && this.muscle.model && this.muscle.model.representing_nodes
            } else {
                return (
                    this.posture &&
                    this.posture.depth &&
                    this.posture.depth.visibility &&
                    this.posture.depth.visibility[this.nonNegativeCurrentDepth.toString()]
                )
            }
        },
        nonNegativeCurrentDepth: function () {
            return this.currentDepth < 0 ? 0 : this.currentDepth
        },
        depthCount: function () {
            return this.posture && this.posture.depth && this.posture.depth.steps
        },
        videos: function () {
            return this.muscle && this.muscle.videos
        }
    },
    watch: {
        id: function () {
            this.refreshContents()
        },
        currentDepth: function () {
            this.isMuscleSelected = false
        }
    }
}
</script>

<!-- Resposinve CSS -->
<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+mobile
    .root-container
        flex-direction: column !important
</style>

<style lang="scss">
.breadcrumb a,
.breadcrumb li + li::before {
    color: $light-purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
}
.breadcrumb:not(:last-child) {
    margin-bottom: 0;
}
.widgets {
    margin-top: auto;
}
.widgets .label {
    color: $purple;
    font-weight: bold;
    font-size: 0.85rem;
}
#videos {
    margin-top: 4rem;
}
</style>
