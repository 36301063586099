import groups from '@/json/groups.json'
import _find from 'lodash/find'

let muscles = []
let groupLookup = {}
let isComingSoon = process.env.VUE_APP_BUILD_TARGET === 'coming-soon'

if (!isComingSoon) {
    for (let i = 0; i < groups.length; i++) {
        let group = groups[i]

        for (let j = 0; j < group.items.length; j++) {
            let muscle = group.items[j]
            muscles.push(muscle)
            groupLookup[muscle.id] = group
        }
    }
}

const state = {
    groups,
    muscles,
    groupLookup,
    isComingSoon
}

const getters = {
    allGroups: (state) => (state.isComingSoon ? [] : state.groups),
    isSiteComingSoon: (state) => state.isComingSoon,
    getMuscleById: (state) => (id) => {
        return _find(state.muscles, { id })
    },
    getGroupForMuscleId: (state) => (id) => {
        return state.groupLookup[id]
    }
}

const actions = {}
const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}
