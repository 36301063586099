<template>
    <div class="box noselect">
        <b-field label="Muscle Depth" :custom-class="labelClass">
            <b-slider
                :value="value"
                size="is-medium"
                :min="0"
                :max="depth"
                :tooltip="false"
                :disabled="isDisabled"
                @input="handleInput"
                rounded
            >
                <template v-for="tick in ticks">
                    <b-slider-tick :value="tick" :key="tick">{{ tick }}</b-slider-tick>
                </template>
            </b-slider>
        </b-field>
    </div>
</template>

<script>
export default {
    name: 'MuscleDepth',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        depth: {
            type: Number,
            default: 3
        },
        value: {
            type: Number,
            default: 0
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('change', val)
        }
    },
    computed: {
        ticks: function () {
            if (this.isDisabled) {
                return
            }

            let out = [0]
            for (let i = 0; i < this.depth; i++) {
                out.push(i + 1)
            }
            return out
        },
        isDisabled: function () {
            return this.depth < 1
        },
        labelClass: function () {
            return this.isDisabled ? 'has-text-grey-lighter' : null
        }
    }
}
</script>

<style>
.b-slider.is-medium .b-slider-tick-label {
    margin-top: 0.6em;
    font-weight: bold;
}
</style>
