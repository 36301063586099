import json from '@/json/postures.json'
import _find from 'lodash/find'

const state = {
    postures: json
}

const getters = {
    getPostureById: (state) => (id) => {
        return _find(state.postures, { id: id })
    }
}

const actions = {}
const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}
