import Vue from 'vue'
import Vuex from 'vuex'
import muscles from './modules/muscles'
import postures from './modules/postures'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: { muscles, postures }
})
