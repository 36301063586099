<template>
    <div id="app">
        <div class="container navbar-container is-fluid">
            <nav-header></nav-header>
        </div>
        <section class="main">
            <div class="container">
                <div class="columns is-gapless">
                    <show-at :breakpoints="breakpoints" breakpoint="mediumAndAbove" v-if="!isSiteComingSoon">
                        <div class="column is-one-fifth">
                            <app-menu></app-menu>
                            <!-- desktop -->
                        </div>
                    </show-at>
                    <div id="main-body" class="column has-background-light">
                        <section class="section">
                            <router-view />
                        </section>
                        <hr />
                        <app-isi ref="isi"></app-isi>
                        <transition name="fade">
                            <floating-isi ref="floatingPanel" v-show="isFloatingPanelVisible"></floating-isi>
                        </transition>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</template>

<script>
import breakpoints from './constants/breakpoints'
import { showAt } from 'vue-breakpoints'
import Header from './components/shared/Header'
import Menu from './components/shared/Menu.vue'
import Footer from './components/shared/Footer'
import ISI from './components/shared/ISI'
import FloatingISI from './components/shared/FloatingISI'
import { mapGetters } from 'vuex'

export default {
    name: 'App',
    components: {
        'nav-header': Header,
        'app-menu': Menu,
        'app-footer': Footer,
        'app-isi': ISI,
        'floating-isi': FloatingISI,
        showAt
    },
    data() {
        return {
            breakpoints,
            lastKnownFloatingPanelBoundingRect: null,
            isFloatingPanelVisible: true
        }
    },
    created() {
        window.addEventListener('scroll', this.updateFloatingPanelVisibilityIfNeeded)
        window.addEventListener('resize', this.onWindowResize)
    },
    destroyed() {
        window.removeEventListener('scroll', this.updateFloatingPanelVisibilityIfNeeded)
        window.removeEventListener('resize', this.onWindowResize)
    },
    mounted() {
        this.updateFloatingPanelVisibilityIfNeededOnDOMUpdate()
    },
    methods: {
        getFloatingPanelBoundingRect() {
            // In iPhone if an element is hidden, bounding client rect is zero
            // To avoid this we will cache the value
            if (this.lastKnownFloatingPanelBoundingRect === null) {
                this.lastKnownFloatingPanelBoundingRect = this.$refs.floatingPanel.$el.getBoundingClientRect()
            }
            return this.lastKnownFloatingPanelBoundingRect
        },
        updateFloatingPanelVisibilityIfNeeded() {
            let floatingPanelTop = this.getFloatingPanelBoundingRect().top
            let isiTop = this.$refs.isi.$el.getBoundingClientRect().top
            this.isFloatingPanelVisible = floatingPanelTop < isiTop
        },
        updateFloatingPanelVisibilityIfNeededOnDOMUpdate() {
            this.$nextTick(() => {
                this.updateFloatingPanelVisibilityIfNeeded()
            })
        },
        onWindowResize() {
            // reset cached last known floating panel bound rect
            this.lastKnownFloatingPanelBoundingRect = null

            // if the panel is not visible, we will set the visibility flag
            // to gain new frame on the next dom update
            if (!this.isFloatingPanelVisible) {
                this.isFloatingPanelVisible = true // make it visible for dom to render
                // wait for the dom to update visibility on next tick
                this.updateFloatingPanelVisibilityIfNeededOnDOMUpdate()
            } else {
                // floating panel is visible, no need to force re-render
                this.updateFloatingPanelVisibilityIfNeeded()
            }
        }
    },
    computed: {
        ...mapGetters(['isSiteComingSoon'])
    },
    watch: {
        $route: function (to, from) {
            if (to.name !== from.name) {
                this.updateFloatingPanelVisibilityIfNeededOnDOMUpdate()
            }
        }
    }
}
</script>

<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+mobile
    .navbar-container
        padding-left: 0 !important
        padding-right: 0 !important
    .main
        padding-top: 60px !important
    .section
        padding: 2rem 1.5rem !important
</style>

<style lang="scss">
@import './scss/main';

.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #dadada;
    background-color: white;
    z-index: 9999;
}

.main {
    padding-top: 86px;
}
</style>
