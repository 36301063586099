<template>
    <div class="component gltf-model-container">
        <b-loading :is-full-page="false" :active="isModelLoading"></b-loading>
        <model-gltf
            v-if="renderComponent"
            :src="src"
            ref="gltf"
            class="gltf-renderer"
            :backgroundColor="backgroundColor"
            :lights="lights"
            @on-load="onLoad"
            @on-error="onError"
        ></model-gltf>
    </div>
</template>

<script>
import { ModelGltf } from 'vue-3d-model'

export default {
    components: { ModelGltf },
    props: {
        src: String,
        eligibleChildNodes: [Array],
        visibleChildNodes: [String, Array]
    },
    data() {
        return {
            backgroundColor: '#f5f5f5',
            cachedNodes: {},
            isModelLoading: true,
            renderComponent: true
        }
    },
    methods: {
        onLoad() {
            this.cacheEligibleChildNodes()
            this.updateVisibility(this.visibleChildNodes)
            this.isModelLoading = false
        },
        onError(error) {
            this.$log.error(error)
            this.isModelLoading = false
            this.showErrorMessage()
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true
            })
        },
        showErrorMessage() {
            const self = this
            this.$buefy.snackbar.open({
                duration: 5000,
                message: 'There was am error while loading the 3D model',
                type: 'is-danger',
                actionText: 'Retry',
                queue: false,
                onAction: () => {
                    self.isModelLoading = true
                    self.forceRerender()
                }
            })
        },
        cacheEligibleChildNodes() {
            this.cachedNodes = {}
            let object = this.$refs.gltf && this.$refs.gltf.object
            if (object) {
                const self = this
                object.traverse(function (child) {
                    if (self.eligibleChildNodes && self.eligibleChildNodes.includes(child.name)) {
                        self.cachedNodes[child.name] = child
                    }
                })
            }
        },
        updateVisibility(visibleChildNodes) {
            if (visibleChildNodes) {
                for (const [name, node] of Object.entries(this.cachedNodes)) {
                    node.visible = visibleChildNodes.includes(name)
                }
            }
        }
    },
    watch: {
        src: function () {
            // check if previous loading in progress
            if (this.isModelLoading) {
                this.forceRerender()
            }

            this.isModelLoading = true
            this.$refs.gltf.controls.reset()
        },
        visibleChildNodes: function (val) {
            this.updateVisibility(val)
        }
    },
    computed: {
        lights: function () {
            let out = [{ type: 'point', color: 0xffffff, distance: 0, intensity: 0.5 }]
            for (let x = -1; x <= 1; x++) {
                for (let y = -1; y <= 1; y++) {
                    for (let z = -1; z <= 1; z++) {
                        out.push({ type: 'directional', color: 0xffffff, position: { x, y, z }, intensity: 0.2 })
                    }
                }
            }
            return out
        }
    },
    beforeDestroy() {
        this.cachedNodes = null
    }
}
</script>

<!-- Resposinve CSS -->
<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+mobile
    .gltf-model-container
        margin-top: 1rem
        height: 360px !important
</style>

<style lang="scss">
.gltf-model-container {
    height: 506px;
    position: relative;
}

.gltf-model-container .b-skeleton {
    height: 100%;
}

.loading-overlay .loading-icon:after {
    border: 2px solid $light-purple;
    border-right-color: transparent;
    border-top-color: transparent;
}
</style>
