<template>
    <component :is="iconComponent" />
</template>

<script>
// all icon that you need
import { ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons'

export default {
    name: 'VueFeatherIconPack',
    components: {
        ChevronUpIcon,
        ChevronDownIcon
    },
    props: {
        icon: [String, Array],
        size: String,
        customClass: String
    },
    computed: {
        iconComponent() {
            return this.icon[1] + 'Icon'
        }
    }
}
</script>
