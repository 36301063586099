import { render, staticRenderFns } from "./FloatingISI.vue?vue&type=template&id=56ae17a1&"
var script = {}
import style0 from "./FloatingISI.vue?vue&type=style&index=0&id=56ae17a1&prod&lang=sass&"
import style1 from "./FloatingISI.vue?vue&type=style&index=1&id=56ae17a1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports